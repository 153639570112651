import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* background: #ffffff; */
`;

const InnerSection = styled.div`
    @media only screen and (min-width: 1200px) {
        width: 1260px;
        min-height: ${(props) => props.height}px;
    }
    padding: 16px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
`;

const SectionTextContainer = styled.div`
    width: calc(100% - 32px);
    padding: 56px 16px;
    /* @media only screen and (min-width: 480px) {
        width: calc(100% - 32px);
        padding: 56px 16px;
    }
    @media only screen and (min-width: 940px) {
        width: calc(50%);
        padding: 56px 0px;
    }
    @media only screen and (min-width: 1200px) {
        width: calc(50%);
        padding: 56px 0px;
    } */
`;

const TitleText = styled.div`
    font-size: 56px;
    font-weight: bold;
    color: #ffffff;
`;

const DescriptionText = styled.div`
    margin-top: 16px;
    font-size: 24px;
    color: #ffffff;
    /* line-height: 40px; */
    white-space: pre-wrap;
`;

const ContactButton = styled.button`
    margin-top: 24px;
    padding: 8px 16px;
    font-size: 20px;
    color: #ffffff;
    background-color: #ff005c;
    :hover {
        cursor: pointer;
        background-color: #cc004a;
    }
    -webkit-transition: -webkit-background-color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out;
    border: none;
    border-radius: 8px;
`;

function ContactPage() {
    return (
        <Wrapper>
            <InnerSection height={800}>
                <SectionTextContainer>
                    <TitleText>Contact</TitleText>
                    <DescriptionText>
                        If you have questions about our service, please contact
                        to below email.
                    </DescriptionText>

                    <ContactButton
                        onClick={() => {
                            window.location = 'mailto:help@hottestlab.com';
                        }}>
                        help@hottestlab.com
                    </ContactButton>
                </SectionTextContainer>
            </InnerSection>
        </Wrapper>
    );
}

export default ContactPage;
