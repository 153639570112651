import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    --gap: ${(props) => props.spacing || 0}px;
    margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
    width: calc(100% + var(--gap));

    /* display: flex; */
    display: inline-flex;
    flex-direction: ${(props) => props.direction || 'row'};
    align-items: ${(props) => props.alignItems || 'center'};
    justify-content: ${(props) => props.justifyContent || 'flex-start'};
    flex-wrap: wrap;

    & > * {
        margin: var(--gap) 0 0 var(--gap);
        /* :last-child {
            margin: var(--gap) var(--gap) 0 var(--gap);
        } */
    }
`;

export function GridContainer(props) {
    const {
        direction,
        alignItems,
        justifyContent,
        spacing,
        children,
        ...others
    } = props;

    return (
        <Container
            direction={direction}
            alignItems={alignItems}
            justifyContent={justifyContent}
            spacing={spacing}
            {...others}>
            {children}
        </Container>
    );
}

const Item = styled.div`
    width: 100%;
    flex: 100%;
    flex-grow: 100%;
    @media only screen and (min-width: ${(props) =>
            props.theme.breakpoint.xs}px) {
        width: calc(100% - var(--gap));
        flex: calc(${(props) => (props.xs / 12) * 100}% - var(--gap));
        flex-grow: calc(${(props) => (props.xs / 12) * 100}% - var(--gap));
    }
    @media only screen and (min-width: ${(props) =>
            props.theme.breakpoint.sm}px) {
        width: calc(100% - var(--gap));
        flex: calc(${(props) => (props.sm / 12) * 100}% - var(--gap));
        flex-grow: calc(${(props) => (props.sm / 12) * 100}% - var(--gap));
    }
    @media only screen and (min-width: ${(props) =>
            props.theme.breakpoint.md}px) {
        width: calc(100% - var(--gap));
        flex: calc(${(props) => (props.md / 12) * 100}% - var(--gap));
        flex-grow: calc(${(props) => (props.md / 12) * 100}% - var(--gap));
    }
    @media only screen and (min-width: ${(props) =>
            props.theme.breakpoint.lg}px) {
        width: calc(100% - var(--gap));
        flex: calc(${(props) => (props.lg / 12) * 100}% - var(--gap));
        flex-grow: calc(${(props) => (props.lg / 12) * 100}% - var(--gap));
    }
    @media only screen and (min-width: ${(props) =>
            props.theme.breakpoint.xl}px) {
        width: calc(100% - var(--gap));
        flex: calc(${(props) => (props.xl / 12) * 100}% - var(--gap));
        flex-grow: calc(${(props) => (props.xl / 12) * 100}% - var(--gap));
    }
`;

export function GridItem(props) {
    const { children, ...others } = props;

    const xs = props.xs || 12;
    const sm = props.sm || xs;
    const md = props.md || sm;
    const lg = props.lg || md;
    const xl = props.xl || lg;

    return (
        <Item xs={xs} sm={sm} md={md} lg={lg} xl={xl} {...others}>
            {children}
        </Item>
    );
}
