import React from 'react';
import styled from 'styled-components';
import { GridItem } from '../base/Grid';

const Wrapper = styled(GridItem)`
    position: relative;
    /* height: 400px; */
`;

const Title = styled.div`
    font-size: 2rem;
    font-weight: bold;
    color: #000000;
`;

const CoverImage = styled.img`
    width: 100%;
    height: 448px;
    /* max-height: 512px; */
    object-fit: cover;
    border-radius: 16px;
`;

const ImageContainer = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 940px) {
        flex-direction: column;
    }
`;

const StyledAnchor = styled.a`
    :not(:last-child) {
        margin-right: 16px;
        @media only screen and (max-width: 940px) {
            margin-right: 0px;
            margin-bottom: 8px;
        }
    }
`;

const Image = styled.img`
    width: auto;
    max-height: 56px;
`;

function GameItem(props) {
    const { linkKey, title, imgSrc, appStoreUrl, googlePlayUrl } = props;

    return (
        <Wrapper xs={12} sm={12} md={6} lg={4} xl={4}>
            {false && <Title>{title}</Title>}

            <a href={`/link/?game=${linkKey}`}>
                <CoverImage src={imgSrc} />
            </a>

            <ImageContainer>
                <StyledAnchor
                    href={appStoreUrl}
                    target="_blank"
                    rel="noopener noreferrer">
                    <Image src="/download_app_store.png" />
                </StyledAnchor>
                <StyledAnchor
                    href={googlePlayUrl}
                    target="_blank"
                    rel="noopener noreferrer">
                    <Image src="/download_google_play.png" />
                </StyledAnchor>
            </ImageContainer>
        </Wrapper>
    );
}

export default GameItem;
