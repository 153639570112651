const GameDict = {
    dailyworker: {
        key: 'dailyworker',
        title: 'Daily Worker',
        imgSrc: '/daily_worker_image.png',
        appStoreUrl: 'https://apps.apple.com/us/app/daily-worker/id1569344522',
        googlePlayUrl:
            'https://play.google.com/store/apps/details?id=com.hottestlab.dailyworker',
    },
    flyingbird: {
        key: 'flyingbird',
        title: 'Flying Bird',
        imgSrc: '/flying_bird_image.png',
        appStoreUrl:
            'https://apps.apple.com/us/app/flying-bird-high/id1574023063',
        googlePlayUrl:
            'https://play.google.com/store/apps/details?id=com.hottestlab.flyingbird',
    },
};

export default GameDict;
