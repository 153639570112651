import AppColors from './AppColors';

const StyledComponentsTheme = {
    color: AppColors,
    layout: {
        // maxWidth: '944px',
    },
    breakpoint: {
        xs: 0,
        sm: 480,
        md: 940,
        lg: 1200,
        xl: 1920,
    },
};

export default StyledComponentsTheme;
