// src/components/base/App.js

import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';

import Layout from './Layout';
// Page
import IndexPage from '../page/IndexPage';
import TermsPage from '../page/TermsPage';
import PrivacyPage from '../page/PrivacyPage';
import ContactPage from '../page/ContactPage';
import LinkPage from '../page/LinkPage';

function App(props) {
    return (
        <Router>
            <Layout>
                <Switch>
                    <Route exact path="/" component={IndexPage} />
                    <Route exact path="/terms" component={TermsPage} />
                    <Route exact path="/privacy" component={PrivacyPage} />
                    <Route exact path="/contact" component={ContactPage} />
                    <Route exact path="/link" component={LinkPage} />
                </Switch>
            </Layout>
        </Router>
    );
}

export default App;
