import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import Header from './Header';
import Footer from './Footer';
import StyledComponentsTheme from '../../theme/StyledComponentsTheme';

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /* background: linear-gradient(
        135deg,
        rgba(225, 72, 133, 1) 0%,
        rgba(236, 68, 100, 1) 60%,
        rgba(255, 62, 44, 1) 100%
    ); */
    /* background: linear-gradient(135deg, #000620, #090022); */
    background: linear-gradient(135deg, #111111, #151515);
    & > * {
        font-family: 'Montserrat', sans-serif;
    }
`;

function Layout(props) {
    return (
        <ThemeProvider theme={StyledComponentsTheme}>
            <Wrapper>
                <Header />

                {props.children}

                <Footer />
            </Wrapper>
        </ThemeProvider>
    );
}

export default Layout;
