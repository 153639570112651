import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: #333333; */
`;

const SocialContainer = styled.div`
    padding: 64px 16px 16px 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    & > * {
        :not(:last-child) {
            margin-right: 56px;
        }
    }
`;

const ContentContainer = styled.div`
    padding: 16px 16px 64px 16px;
    /* width: 100%; */
    @media only screen and (min-width: 1200px) {
        width: 1260px;
    }
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

const StyledAnchor = styled.a`
    text-decoration: none;
    color: white;
`;

const Text = styled.div`
    font-size: 16px;
    color: #ffffff;
    :not(:last-child) {
        margin-bottom: 12px;
    }
`;

const CopyrightText = styled.div`
    margin-top: 32px;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
`;

function Footer(props) {
    return (
        <Wrapper>
            <SocialContainer>
                <StyledAnchor
                    href="https://www.facebook.com/Hottest-Lab-105130104908960/"
                    target="_blank"
                    rel="noopener noreferrer">
                    <ion-icon size="large" name="logo-facebook" />
                </StyledAnchor>

                <StyledAnchor
                    href="https://www.instagram.com/hottest_lab/"
                    target="_blank"
                    rel="noopener noreferrer">
                    <ion-icon size="large" name="logo-instagram" />
                </StyledAnchor>

                <StyledAnchor
                    href="http://www.youtube.com/channel/UCgHHhjXW8Svj6KJDAqdAtTA?sub_confirmation=1"
                    target="_blank"
                    rel="noopener noreferrer">
                    <ion-icon size="large" name="logo-youtube" />
                </StyledAnchor>
            </SocialContainer>

            <ContentContainer>
                <Text>Hottest Lab Inc.</Text>

                <Text>Representative: Inje Lee</Text>

                <Text>
                    Address: 3F #58, 128 Teheran-ro, Gangnam-gu, Seoul,
                    Republic of Korea
                </Text>

                <Text>Company Registration Number: 318-87-02079</Text>

                <Text>
                    E-Commerce Registration Number: Je 2021-Seoul Gangnam-00547
                    Ho
                </Text>

                <StyledAnchor href="mailto:help@hottestlab.com">
                    <Text>help@hottestlab.com</Text>
                </StyledAnchor>

                <CopyrightText>
                    Copyright ⓒ Hottest Lab Inc. All rights reserved.
                </CopyrightText>
            </ContentContainer>
        </Wrapper>
    );
}

export default Footer;
