import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import GameDict from '../../constant/GameDict';

const Wrapper = styled.div`
    width: 100%;
    min-height: 920px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* background: #ffffff; */
`;

const ConnectingText = styled.div`
    font-size: 2rem;
    color: #ffffff;
`;

const MOBILE_OS = {
    WINDOWS_PHONE: 0,
    ANDROID: 1,
    IOS: 2,
    MAC_OS: 3,
    UNKNOWN: 4,
};

function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return MOBILE_OS.WINDOWS_PHONE;
    }

    if (/android|Android/i.test(userAgent)) {
        return MOBILE_OS.ANDROID;
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return MOBILE_OS.IOS;
    }

    if (/Macintosh|Mac OS/.test(userAgent)) {
        return MOBILE_OS.MAC_OS;
    }

    return MOBILE_OS.UNKNOWN;
}

function LinkPage(props) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    useEffect(() => {
        // Redirection by game
        if (params.game) {
            // params.game === 'dailyworker'
            const gameObj = GameDict[params.game];
            if (!gameObj) {
                window.location = '/';
            }

            switch (getMobileOperatingSystem()) {
                case MOBILE_OS.WINDOWS_PHONE:
                    window.location.replace(gameObj.googlePlayUrl);
                    break;
                case MOBILE_OS.ANDROID:
                    window.location.replace(gameObj.googlePlayUrl);
                    break;
                case MOBILE_OS.IOS:
                case MOBILE_OS.MAC_OS:
                    window.location.replace(gameObj.appStoreUrl);
                    break;
                case MOBILE_OS.UNKNOWN:
                default:
                    window.location.replace(gameObj.appStoreUrl);
                    break;
            }
        } else {
            window.location = '/';
        }
    }, [params.game]);

    return (
        <Wrapper>
            <ConnectingText>Connecting...</ConnectingText>
        </Wrapper>
    );
}

export default LinkPage;
